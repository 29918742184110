jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false
    }

    slider.flickity(config)
  })
})


jQuery(function($) {
  $('.slider-cell-left').each(function() {
    var slider2 = $(this)
    var config2 = {
      pageDots: slider2.hasClass('has-dots'),
      prevNextButtons: slider2.hasClass('has-arrows'),
      wrapAround: false,
      imagesLoaded: true,
      cellAlign: 'left',
      contain: false
    }

    slider2.flickity(config2)
  })
})