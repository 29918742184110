jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/accordion.js');
  require('./components/slider.js');

  loadMoreProjects.init();


  if($('.projects-slider').is(':last-child')) {
    document.querySelector('.projects-slider').classList.add('slider-is-last');
  }


  $('.banner-title').each(function(){
    var text = $(this).text().split(' ');
    if(text.length < 2)
      return;

      for(let i = 2; i < text.length; i++) {
        text[i] = '<span class="secondWord">'+text[i]+'</span>';
      }

    $(this).html( text.join(' ') );
  });
});


// Calculate Header Height
var box = document.querySelector('.header');
var height = box.offsetHeight;
document.querySelector('.banner-margin').style.marginTop = height + 'px';

window.addEventListener('resize', resizeFunction);
function resizeFunction() {
  var box2 = document.querySelector('.header');
  var height2 = box2.offsetHeight;
  document.querySelector('.banner-margin').style.marginTop = height2 + 'px';
}


// Load More Posts
var loadMoreProjects = {
	init: function() {
    jQuery('#load-more').click(function(){
      loadMoreProjects.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_projects',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more').replaceWith('<a class="button no-posts" href="#load-container">Back to top</a>');
				}

				return;
			}
		});
	}
}